'use strict';

import App from 'App';
import User from 'User';
import Server from 'server/Server';
import FieldView from 'views/record/Field';
import Context from 'models/context/Context';

import { Header, Column } from 'models/listing/Header';

import GetGridDataRequest from 'server/protocol/request/task/GetGridData';
import TextField from './Text';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import { Filter } from '../../../parametrage/structures/Filter';
import ScriptUtils from 'utils/ScriptUtils';

let $ = require('jquery');

class CoupleField extends TextField {
    private currentPage: number;
    private previousQuery: string;
    private requestHeaders: Header;
    private resultOptions: Array<{ key: string, value: string}>;
    private associatedField: CoupleField;
    private displayedLabel: string;
    private nbElements:number;
    private currentCallPage:number;

    constructor(options) {
        super(options);
        this.resultOptions = [];
        this.nbElements = 15;
    }

    public setAssociatedField(associated: CoupleField): void {
        this.associatedField = associated;
    }

    render():any {
        super.render();
        let linkedCouple = this.fieldConfig.getLinkedCouple();
        if(linkedCouple.isFusion() && linkedCouple.getLabelField().getDatafieldId() === this.fieldConfig.getDatafieldId()) {
            this.$el.hide();
        }
    }

    public onBeforeFieldChange(fromServer: boolean): void {
        //Update of the associate couple field
        var selectEl:any = this.$el.find('.selectpicker');
        // If the value is changed from the server, do not modified the associatedField
        // else the value is set by the user from the UI so we can modify the associatedField
        if(selectEl.select2('data') && !fromServer) {
            let item = selectEl.select2('data')[0];
            let associatedValue = '';
            if(item.data) {
                let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
                let coupleKey = this.fieldConfig.getCoupleKey();
                let coupleLabel = this.fieldConfig.getLinkedCouple().getLabelField().getDatafieldId();
                let friendColumn = this.fieldConfig.isCoupleKey() ? datafieldToColumnId[coupleLabel] : datafieldToColumnId[coupleKey];
                associatedValue = this.associatedField.parseValue(item.data.values.columns[friendColumn].value);
            }
            this.associatedField.setModelValue(associatedValue);
            this.associatedField.render();
            this.associatedField.renderPosition();
            this.associatedField.onDOMUpdated();
        }
    }

    onDOMUpdated () {
        super.onDOMUpdated();
        var me = this;
        me.setupSelect2();

        let linkedCouple = this.fieldConfig.getLinkedCouple();
        if(linkedCouple.isFusion() && linkedCouple.getKeyField().getDatafieldId() === this.fieldConfig.getDatafieldId()) {
            this.displayedLabel = this.getLinkedLabel();
            this.$el.find('.select2-selection__rendered').text(this.displayedLabel);
        }
    }

    setupSelect2() {
        var me = this;
        var selectEl:any = this.$el.find('.selectpicker');
        me.currentPage = 0;
        selectEl.select2({
            ajax: {
                transport: this.callGetGridData.bind(me),
                delay: 250,
                processResults: this.processResults.bind(me)
            },
            minimumInputLength: 0,
            templateResult: me.buildFromTemplate.bind(me),
            templateSelection: me.selectItem.bind(me),
            dropdownAutoWidth: true,
            width: '100%',
            allowClear: true,
            placeholder: '',
        });

        selectEl.on('select2:unselecting', function(ev) {
            if (ev.params.args.originalEvent) {
                // When unselecting (in multiple mode)
                ev.params.args.originalEvent.stopPropagation();
            } else {
                // When clearing (in single mode)
                $(this).one('select2:opening', function(ev) { ev.preventDefault(); });
            }
        });

        selectEl.on('select2:opening', this.checkForSelectBlocked.bind(this, selectEl));

        // Reset of the page selection
        selectEl.on('select2:open', function (e) {
            me.currentPage = 0;
        });

        var displayMode = this.displayMode;
        if(displayMode === 'tablet'){
            // If the field width is 0, it should be hidden together with the select2 container
            let fieldWidth = this.getState().getPosition(this.displayMode).width;
            if(fieldWidth === '0%' || fieldWidth === '0') {
                this.$el.find('select').hide();
                this.$el.find('.select2-container').hide();
            } else {
                this.$el.find('.select2-container').css({
                    width: '100%'
                });
            }
        }else{
            if(this.locationProperties){
                var left = this.locationProperties.labelLeft;

                if (this.locationProperties.labelVisible === false) {
                    left = this.locationProperties.fieldLeft;
                }

                if (!left) {
                    left = '0';
                }
                this.$el.find('.select2-container').css(this.getFieldPositionModifier(this.locationProperties, left));
            }
        }
    }

    buildFromTemplate (item) {
        if (item.loading) {
            return item.text;
        } else {
            if (item.data) {
                var result = $('<div class="row coupleEntry">' + this.templateCouple(item.data, this.requestHeaders) + '</div>');
                result.find('.coupleiconPart').css({
                    padding: '0px',
                    width: '36px'
                });
                return result;
            } else {
                return '';
            }
        }
    }

    templateCouple(colVals, header){
        var htmlData = '';
        var firstDone = false;
        let columns = colVals.values.columns;
        let headers = header.getColumns();
        htmlData += '<div class="text"><div class="title">';
        for (var key in headers) {
            var currentHeader: Column = headers[key];
            if (! currentHeader.isHidden()) {
                var data = columns[currentHeader.getId()];
                if (data && data.value) {
                    if (firstDone && currentHeader.getColumnSeparator()) {
                        htmlData += '<span class="header-colSeparator">' + currentHeader.getColumnSeparator() + '</span>';
                    }else{
                        htmlData += '<span class="header-colSeparator">&nbsp;</span>';
                    }

                    if (data.humanValue && data.humanValue !== '') {
                        htmlData += '<span class="header-value">' + data.humanValue + '</span>';
                    } else {
                        htmlData += '<span class="header-value">' + data.value + '</span>';
                    }
                    firstDone = true;
                }
            }
        }
        htmlData += '</div></div>';
        //We do not display the icon in couples as the field is too small.. we could want to tough
        //htmlData += '<div class="icon"><img class="mainIcon coverIcon" src="'+Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big,record/128,100,64,36/' + colVals.values.icon)+'"/></div>';
        return htmlData;
    }

    selectItem (item) {
        if(item.data) {
            let linkedCouple = this.fieldConfig.getLinkedCouple();
            //On item selection from select2 input, we need to set the other couple field
            let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
            let coupleKey = this.fieldConfig.getCoupleKey();
            let coupleLabel = linkedCouple.getLabelField().getDatafieldId();

            let columnToUse, friendFieldColumn;
            let keyValue, labelValue;
            if(this.fieldConfig.isCoupleKey()) {
                columnToUse = datafieldToColumnId[coupleKey];
                friendFieldColumn = datafieldToColumnId[coupleLabel];
                keyValue = item.data.values.columns[columnToUse].value;
                labelValue = item.data.values.columns[friendFieldColumn].value;
            } else {
                columnToUse = datafieldToColumnId[coupleLabel];
                friendFieldColumn = datafieldToColumnId[coupleKey];
                keyValue = item.data.values.columns[friendFieldColumn].value;
                labelValue = item.data.values.columns[columnToUse].value;
            }

            //The displayed value depends of if the couple is just a field
            //or a combinaison of multiples (fusion)
            if(linkedCouple.isFusion()) {
                if(linkedCouple.isSwitched()) {
                    this.displayedLabel = labelValue + ' ' + keyValue;
                    return this.displayedLabel;
                } else {
                    this.displayedLabel = keyValue + ' ' + labelValue;
                    return this.displayedLabel;
                }
            } else {
                return item.data.values.columns[columnToUse].value;
            }
        } else {
            //We get straight value at initialisation
            return item.text;
        }
    }

    callGetGridData (params, success, failure) {
        if (params.data.q !== this.previousQuery) {
            this.currentPage = 0;
        }
        this.currentCallPage = this.currentPage;

        let dataRequest = new GetGridDataRequest(this.domainContext, this.fieldConfig.getTask().getId(), this.fieldConfig.getCoupleGrid());
        dataRequest.setFilterId(this.fieldConfig.getCoupleFilter());
        dataRequest.setRange((this.currentPage * this.nbElements),((this.currentPage + 1) * this.nbElements) + 1);

        let filterContext = new Context({
            contextId: 'filter'
        });
        filterContext.addParameter('customText', params.data.q);
        if(params.data.q){
            dataRequest.setSessionVar('CUSTOMIZED_FILTER', true);
        }

        dataRequest.setFilterContext(filterContext);
        let requestContext = new Context();
        this.trigger('panel.onPrepareContext', requestContext, true);
        if(requestContext.getContextId() === 'page'){
            dataRequest.setPageContext(requestContext);
        }else{
            dataRequest.setRecordContext(requestContext);
        }

        let ressources = []
        if(this.fieldConfig.getCoupleFilter() !== 'DEFAULT_FILTER') {
            ressources.push(CClientConfiguration.getFilter(this.fieldConfig.getCoupleFilter(), this.domainContext));
        }

        let me = this;
        Promise.all(ressources)
            .then((res) => {
                if(res.length === 1) {
                    //We've a specific filter, we check for values
                    let filter: Filter = res[0];
                    let screenDfCache = {};
                    this.trigger('panel.onPrepareScriptContext', screenDfCache, true);
                    let filterEntries = filter.getElements();
                    let finalFilters = [];
                    for(let key in filterEntries) {
                        let filterEntry = filterEntries[key];
                        let finalValue;
                        let permanent = filterEntry.getPermanentValue();
                        let defaultVal = filterEntry.getDefaultValue();
                        if(permanent) {
                            if(permanent.includes('$')) {
                                //we need scripts
                                permanent = ScriptUtils.evalInContext(permanent, screenDfCache);
                            }
                            finalValue = permanent;
                        } else if(defaultVal) {
                            if(defaultVal.includes('$')) {
                                //we need scripts
                                defaultVal = ScriptUtils.evalInContext(defaultVal, screenDfCache);
                            }
                            finalValue = defaultVal;
                        }
                        if(finalValue) {
                            finalFilters.push({
                                datafieldId: filterEntry.getDatafieldId(),
                                value: finalValue
                            })
                        }
                    }
                    if(finalFilters.length > 0) {
                        filterContext.addFields(finalFilters);
                    }
                }
                Server.performRequest(dataRequest)
                .then(success)
                .catch((error) => {
                    App.displayError(error);
                    failure(error);
                });
            });
    }

    callGetGridDataWithCustomFilter(customFilter) {
        this.currentPage = 0;
        this.currentCallPage = this.currentPage;
        let dataRequest = new GetGridDataRequest(this.domainContext, this.fieldConfig.getTask().getId(), this.fieldConfig.getCoupleGrid());
        dataRequest.setFilterId(this.fieldConfig.getCoupleFilter());
        dataRequest.setRange((this.currentPage * this.nbElements),((this.currentPage + 1) * this.nbElements)+1);

        let filterContext = new Context({
            contextId: 'filter'
        });
        filterContext.addParameter('customText', customFilter);
        dataRequest.setSessionVar('CUSTOMIZED_FILTER', true);

        dataRequest.setFilterContext(filterContext);
        let requestContext = new Context();
        this.trigger('panel.onPrepareContext', requestContext, true);
        if(requestContext.getContextId() === 'page'){
            dataRequest.setPageContext(requestContext);
        }else{
            dataRequest.setRecordContext(requestContext);
        }

        return Server.performRequest(dataRequest);
    }

    onCoupleItemselected (itemId) {
        /*var selectedModel = this.latestCouplePop.navigator.currentRecords.at(itemId);
        this.latestCouplePop.$el.modal('hide');
        var value = selectedModel.get('columns')[this.model.get('optionKey')].value;
        var humanValue = selectedModel.get('columns')[this.model.get('optionValue')].value;
        this.model.set('value', value);
        this.model.set('humanValue', humanValue);
        this.render();*/
        throw new Error('Not implemented yet with configuration');
    }

    getSelectedOption (selectedValue) {
        /*var me = this;
        return new Promise((accept,reject) => {
            var selectedValues = me.model.get('options');
            for(var idx in selectedValues) {
                if(selectedValues[idx].key === selectedValue) {
                    accept(selectedValues[idx].value);
                    return;
                }
            }
            // The selected option was not found
            me.callGetGridDataWithCustomFilter(selectedValue)
                .then((data) => {
                    me.processResults(data);
                    selectedValues = me.model.get('options');
                    for(var idx in selectedValues) {
                        if(selectedValues[idx].key === selectedValue) {
                            accept(selectedValues[idx].value);
                            return;
                        }
                    }
                }).catch((error) => {
                    reject(error);
                });
        });*/
        throw new Error('Not implemented yet with configuration');
    }

    processResults(data) {
        /*
         * Registering headers if not set yet
         */
        if(this.requestHeaders === undefined){
            this.requestHeaders = new Header(data.headers);
        }

        var items = [];
        //Empty result if no result
        data.items = data.items || [];

        /*
         *  Formatting of the result array. All items of
         *  select2 must be at least in the following format
         *
         *  {
         *    id: ''
         *    text: ''
         *  }
         */
        let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
        let coupleKey = this.fieldConfig.getCoupleKey();
        //In order to get the label, we need to look at the second field of the couple
        let coupleLabel = this.fieldConfig.getLinkedCouple().getLabelField().getDatafieldId();
        let columnToUse = this.fieldConfig.isCoupleKey() ? coupleKey : coupleLabel;
        let maxItems = Math.min(data.items.length,this.nbElements);
        for (var i = 0; i < maxItems; i++) {
            //We add an extra parameter that will be
            //stored as 'data' in the item. This data element
            //contains every column values of the items
            var valueData = {
                itemId: this.currentPage * this.nbElements + i,
                values: data.items[i]
            };

            items.push({
                id: data.items[i].columns[datafieldToColumnId[columnToUse]].value,
                text: '',
                data: valueData
            });

            this.resultOptions.push({
                key : valueData.values.columns[datafieldToColumnId[coupleKey]].value,
                value : valueData.values.columns[datafieldToColumnId[coupleLabel]].value
            });
        }

        /*
         * The load more parameter tells the plugin that it
         * has to add a load more element at the end of the
         * item list and when the user scrolls to it, the
         * plugin loads the next page of data
         */
        var loadMore = data.items.length > this.nbElements;
        var result = {
            results: items,
            pagination: {
                more: loadMore
            }
        };

        /*
         * If we're on the first page we've to add an empty
         * element so that the user can clear the combo
         */
        if (this.currentPage === 0) {
            result.results.unshift({
                id: '',
                text: ''
            });
        }

        /*
         * Next call will ask for next page
         */
        if(this.currentPage === this.currentCallPage) {
            this.currentPage = this.currentPage + 1;
        }

        return result;
    }

    private getLinkedLabel() {
        let linkedCouple = this.fieldConfig.getLinkedCouple();
        //On item selection from select2 input, we need to set the other couple field
        //let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
        let coupleKey = this.fieldConfig.getCoupleKey();
        let coupleLabel = linkedCouple.getLabelField().getDatafieldId();
        let columnToUse, friendFieldColumn;
        let keyValue, labelValue;
        if(this.fieldConfig.isCoupleKey()) {
            //columnToUse = datafieldToColumnId[coupleKey];
            //friendFieldColumn = datafieldToColumnId[coupleLabel];
            keyValue = this.fieldState.getValue();
            labelValue = this.associatedField.getValue();
        } else {
            //columnToUse = datafieldToColumnId[coupleLabel];
            //friendFieldColumn = datafieldToColumnId[coupleKey];
            keyValue = this.associatedField.getValue();
            labelValue = this.fieldState.getValue();
        }

        if(linkedCouple.isSwitched()) {
            return labelValue + ' ' + keyValue;
        } else {
            return keyValue + ' ' + labelValue;
        }
    }

    public focus() {
        let selectEl:any = this.$el.find('.selectpicker');
        selectEl.focus();
    }


    getValue() {
        let defaultValue = super.getValue();
        if(this.fieldConfig.isCoupleKey()) {
            return defaultValue;
        } else {
            let regexp = /([A-Z]{2})={(.*?)}/g
            let matches = [];
            let intLabelMap = {};
            while((matches = regexp.exec(defaultValue)) !== null) {
                intLabelMap[matches[1]] = matches[2];
            }
            let localeLabel = intLabelMap[User.getLocale().toUpperCase()];
            localeLabel = localeLabel === undefined ? intLabelMap['FR'] : localeLabel;
            if(localeLabel !== undefined) {
                return localeLabel;
            } else {
                return defaultValue;
            }
        }
    }

    setModelValue (value) {
        super.setModelValue(value);
        // If we are setting the key, the label has to be update accordingly
        if(this.fieldConfig.isCoupleKey() && this.requestHeaders === undefined) {
            if(value) {
                let me = this;
                me.callGetGridDataWithCustomFilter(value)
                    .then((result) => {
                        let data = me.processResults(result) as any;
                        let initialItem;
                        data.results.forEach(item => {
                            if(item.id === value) {
                                initialItem = item;
                                return;
                            }
                        });
                        if(initialItem && me.associatedField) {
                            me.associatedField.processResults(result);
                            let label = me.associatedField.selectItem(initialItem);
                            me.associatedField.setModelValue(label);
                            me.associatedField.render();
                            me.associatedField.renderPosition();
                            me.associatedField.setupSelect2();
                        }
                    })
                    .catch(App.displayError);
            }
        }
    }
}

export default CoupleField;
