'use strict';

import App from 'App';
import User from 'User';
import Server from 'server/Server';
import * as Backbone from 'backbone';
import DeviceUtils from 'utils/DeviceUtils';
import TaskFactory from '../factory/TaskFactory';
import GetTaskRequest from '../server/protocol/request/task/GetTask';
import DesktopView from '../views/Desktop';
import Login from '../views/Login';
import TaskMenuView from '../views/tasks/TaskMenu';
import LoadingMask from 'views/loading/LoadingMask';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import DomainManager from 'DomainManager';
import { TaskView } from '../views/tasks/Task';

class Router extends Backbone.Router {
    private isUrlRewriteOnly : boolean;
    lastFragment : string;
    isApplicationCall: boolean = false;
    isNavigatorBack: boolean = false;
    constructor() {
        super();
        this.isUrlRewriteOnly = false;
        var me = this;
        this.routes = {
            '': 'displayLogin',
            'login(/*path)': 'displayLogin',
            'desktop': 'displayDesktop',
            'task/:taskId(/*path)': 'displayNewTask'
        };

        this.execute = function(callback, args, name) {
            let tmpAppCall = this.isApplicationCall;
            this.isApplicationCall = false;
            if(tmpAppCall && !this.isUrlRewriteOnly) {
                if (callback) callback.apply(this, args);
            } else {
                if(decodeURIComponent(this.lastFragment).indexOf(decodeURIComponent(Backbone.history.getFragment())) === -1) {
                    callback.apply(this, args);
                } else if(!tmpAppCall){
                    this.isNavigatorBack = true;
                    App.goBack(null);
                    this.isNavigatorBack = false;
                }
            }
        };

        this.navigate = function(route, options) : any {
            this.lastFragment = route;
            this.isApplicationCall = true;
            this.isUrlRewriteOnly = !options.trigger;
            Backbone.Router.prototype.navigate.call(this, route, options);
        };

        Backbone.Router.apply(this);
    }

    displayLogin (optionalPath) {
        if(Server.hasToken()) {
            if(this.lastFragment !== undefined && this.lastFragment !== '') {
                this.isApplicationCall = true;
                Backbone.Router.prototype.navigate.call(this,this.lastFragment, {trigger: true});
            } else {
                this.displayDesktop;
            }
        } else {
            if (App.taskMenu) {
                App.taskMenu.$el.hide();
            }

            if (App.views.applicationLogin === undefined) {
                App.views.applicationLogin = new Login({
                    el: '.loginContainer',
                    domainContext: null
                });
                App.views.applicationLogin.render();
            }
            App.views.applicationLogin.loginPath = optionalPath;
            App.showView(App.views.applicationLogin);
        }
        App.views.applicationLogin.loginPath = optionalPath;
        if (sessionStorage.saiUserToken !== undefined && sessionStorage.saiUserToken !== 'undefined') {
            App.views.applicationLogin.tokenConnect();
        } else {
            App.showView(App.views.applicationLogin);
        }
    }

    displayDesktop () {
        if (Server.hasToken()) {
            this.lastFragment = Backbone.history.getFragment();
            if (App.views.applicationDesktop === undefined) {
                App.views.applicationDesktop = new DesktopView({
                    el: '.desktopContainer'
                });
                App.views.applicationDesktop.render();
            } else {
                App.views.applicationDesktop.refreshDashboard();
            }
            App.showView(App.views.applicationDesktop);
            App.views.taskContainer.hide();
            if(App.views.applicationLogin !== undefined) {
                App.views.applicationLogin.$el.hide();
            }
        } else {
            this.navigate('#login', {trigger: true, replace: true});
        }
    }

    displayNewTask (taskName, display, initialpropsOverride?) : Promise<TaskView> {
        return new Promise( (accept,reject) => {
            if (Server.hasToken()) {
                this.lastFragment = Backbone.history.getFragment();
                //We need intel on the task before opening it
                if (!App.taskMenu) {
                    App.taskMenu = new TaskMenuView({
                        el: '#taskMenuBar',
                        domainContext: DomainManager.getCurrentContext(User.getCurrentDomain())
                    });
                    App.taskMenu.render();
                }

                if (!DeviceUtils.isFullScreenPageNav()) {
                    App.taskMenu.$el.show();
                }

                let newRequest = new GetTaskRequest(DomainManager.getCurrentContext(User.getCurrentDomain()), taskName);
                let loadingReqId;
                LoadingMask.requestLoading('Chargement de la tâche')
                    .then((reqId) => {
                        loadingReqId = reqId;
                        return Promise.all([Server.performRequest(newRequest), CClientConfiguration.getTask(taskName, DomainManager.getCurrentContext(User.getCurrentDomain()))]);
                    })
                    .then(([result, task]) => {
                        result.initialEntityDisplay = display;
                        if (initialpropsOverride === null) {
                            // if initialpropsOverride ==> we come from a reload
                            let sessionProps = sessionStorage.saiTaskProps;
                            if (sessionProps !== undefined && sessionProps !== 'undefined' && JSON.parse(sessionProps).taskId === taskName) {
                                initialpropsOverride = JSON.parse(sessionStorage.saiTaskProps);
                            }
                        } else {
                            sessionStorage.saiTaskProps = JSON.stringify(initialpropsOverride);
                        }
                        result.initialpropsOverride = initialpropsOverride;
                        var newTaskPromise = TaskFactory.createTask(task, result, DomainManager.getCurrentContext(User.getCurrentDomain()));
                        newTaskPromise.then((newTask) => {
                            if (result.initialEntityDisplay !== 'none') {
                                App.stackNewTask(newTask, result);
                                App.taskMenu.addTask(newTask);
                                App.taskMenu.setSelectedEntry(newTask.getTaskInstanceId());
                                if (decodeURIComponent(Backbone.history.getFragment()) !== 'task/'+taskName) {
                                    this.navigate('#task/'+taskName, {trigger: false, replace: true});
                                }
                            }
                            newTask.getReadyState().then(() => {
                                accept(newTask)
                            });
                        }).catch((e) => {
                            console.error('not enough intel to determine task type, try some amd');
                            console.error(e);
                            reject(e);
                        })
                    })
                    .catch(App.displayErrorMessage)
                    .then(() => { LoadingMask.hide(loadingReqId);});
                    if(App.views.applicationLogin !== undefined) {
                        App.views.applicationLogin.$el.hide();
                    }
            } else {
                this.navigate('#login/task/' + taskName, {trigger: true, replace: true});
            }
        });
    }
}

export default Router;
