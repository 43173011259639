import Listing from 'models/listing/Listing';

import Backbone from 'backbone';
import { SAIView } from '../../Additions';
import { Header } from '../../models/listing/Header';

class List extends SAIView {
    initialize(options){
        this.dataListing = new Listing({
            taskId: options.taskId,
            gridId: options.gridId,
            formatterId: options.formatterId,
            filterId: options.filterId,
            start: options.start,
            end: options.end,
            items: options.data,
            headers: options.headers,
            formatter: options.formatter,
            domainContext: options.domainContext,
            sessionVars: options.sessionVars
        });
        this.listenTo(this.dataListing, 'listing.onPrepareContext', this.onContextRequested.bind(this));
        this.itemsViews = [];

        this.listenTo(this.dataListing, 'rowsAdded', this.onNewRowsAdded.bind(this));
        this.listenTo(this.dataListing, 'reset', this.onDataReset.bind(this));
        this.listenTo(this.dataListing, 'rowUpdated', this.onRowUpdated.bind(this));
        this.listenTo(this.dataListing,'headersUpdated', this.onHeaderUpdated.bind(this));

        this.multiSelect = options.multiSelect || false;

        //moved to renderer
        //this.lineStyle = options.lineStyle;
        //this.lineExpanse = options.lineExpanse;

        this.renderer = options.renderer;
        if(this.renderer && options.el){
            this.renderer.setElement(options.el);

            this.renderer.setHeaders(this.dataListing.getHeaders());
            this.renderer.setFormatter(this.dataListing.getFormatter());
        }

        this.domainContext = options.domainContext;
    }

    onContextRequested(context) {
        this.trigger('list.onPrepareContext', context);
    }

    setElement(element){
        super.setElement(element);
        if(this.renderer){
            this.renderer.setElement(element);
        }
    }

    render(){
        var me = this;

        if(this.renderer){
            this.renderer.renderCore();

            if(this.dataListing.hasData()){
                this.renderItems(this.dataListing.getRows());
            }
        }else{
            console.error('[list] No renderer means no rendering');
        }
    }

    renderItems(items){
        this.itemsViews = this.itemsViews.concat(this.renderer.renderItems(items, this.getListing().hasMorePages(items.length) ? this.getListing().fetchNextPage.bind(this.getListing()) : undefined));
        if(typeof this.multiSelect === 'object' && !this.multiSelect.keyColumn) {
            let headersColums = this.getListing().getHeaders().getColumns();
            for(let key in headersColums) {
                let curCol = headersColums[key];
                if(curCol.getDatafieldId().endsWith('_PK')) {
                    this.multiSelect.keyColumn = curCol.getId();
                    break;
                }
            }
        }
        for(let key in this.itemsViews){
            let newItem = this.itemsViews[key];
            if (newItem.isNew || newItem.isNew === undefined) {
                //Forwarding every events from items
                this.listenTo(newItem, 'all', this.autoTrigger, this);
                if(typeof this.multiSelect === 'object'){
                    this.listenTo(newItem, 'itemClicked', this.selectItem.bind(this, newItem));
                    if(this.selectedKeys && this.selectedKeys.indexOf(newItem.row.getColumnsValues()[this.multiSelect.keyColumn].value) >= 0){
                        //This data row is already selected
                        newItem.select();
                    }
                }
                newItem.isNew = false;
            }
        }
    }
    onRowUpdated(itemId, newRow){
        this.itemsViews[itemId].setRow(newRow[0]);
        this.itemsViews[itemId].render();
    }

    autoTrigger(){
        var args = Array.prototype.slice.call(arguments);
        var eventName = args.shift();
        args.unshift(eventName);
        this.trigger.apply(this, args);
    }

    selectItem(view, row, id){
        view.select();
    }

    getListing(){
        return this.dataListing;
    }

    onNewRowsAdded(newRows){
        this.renderItems(newRows);
    }

    onDataReset(){
        if(this.renderer){
            this.renderer.resetData();
        }
        this.itemsViews = [];
    }

    setSelectedKeys(keys){
        this.selectedKeys = keys;
    }

    addContext(context){
        this.getListing().addContext(context);
    }

    getContexts() {
        return this.getListing().getContexts();
    }

    setPopupMenu(popupMenu) {
        this.dataListing.setPopupMenu(popupMenu);
        if(this.renderer) {
            this.renderer.setPopupMenu(popupMenu);
        }
    }

    onHeaderUpdated(headers) {
        if(this.renderer !== undefined) {
            this.renderer.setHeaders(new Header(headers));
            this.renderer.renderCore();
        }
    }

    unselectItem(itemId, columnId) {
        for(let key in this.itemsViews){
            let newItem = this.itemsViews[key];
            if(newItem.row.getColumnsValues()[columnId].value === itemId) {
                newItem.select();
                break;
            }
        }
    }
}

export default List;
