'use strict';

class User {
    private static _instance: User;
    private locale: string;
    private properties: { [domainId:string] : { [propId:string]: {value: string, forced: boolean} } };
    private availableDomains: Array<string>;
    private currentDomain: string;
    private id: string;
    private name: string;
    private defaultSessionTimeout : number = 30*60*1000; // 30 minutes

    private constructor(){
        this.locale = 'FR';
        this.properties = {};
        this.availableDomains = [];
        this.currentDomain = undefined;
        this.id = 'anonymous';
        this.name = 'anonymous';
    }

    public static get Instance(): User {
        return this._instance || (this._instance = new this());
    }

    public setLocale(locale: string): void{
        if(locale === undefined) {
            locale = 'FR';
        }

        this.locale = locale;
    }

    public getLocale(): string{
        return this.locale;
    }

    public setProperties(properties: { [propId:string]:{value: string, forced: boolean}}, domain: string): void{
        this.properties[domain] = properties;
    }

    private getProperty(propId: string, domainId?: string): string{
        if(domainId === undefined) {
            domainId = this.getCurrentDomain();
        }

        let domainProps = this.properties[domainId] || {};
        return domainProps[propId] === undefined || domainProps[propId].value === '' ? undefined : domainProps[propId].value;
    }

    public getProperties(domainId?: string) : { [propId: string] : { value: string } } {
        if(domainId === undefined) {
            domainId = this.getCurrentDomain();
        }
        return this.properties[domainId] || {};
    }
    public setAvailableDomains(availableDomains: Array<string>): void{
        this.availableDomains = availableDomains;
    }

    public getAvailableDomains(): Array<any> {
        return this.availableDomains;
    }

    public setCurrentDomain(currentDomain: string): void{
        let found = false;
        for(let domainKey in this.availableDomains){
            if(domainKey === currentDomain){
                found = true;
                break;
            }
        }
        if( ! found){
            console.warn('Setting current domain to a non available domain ' + currentDomain + '. The next calls might not work.');
        }
        this.currentDomain = currentDomain;
    }

    public getCurrentDomain(): string{
        return this.currentDomain;
    }

    public getId(): string{
        return this.id;
    }

    public setId(newId: string): void{
        this.id = newId;
    }

    public getWallPaperId(domain?:string): string {
        return this.getProperty('WALLPAPER_WEB', domain);
    }

    public getColorTheme(domain?: string) : string {
        let webTheme = this.getProperty('COLOR_THEME_ID_WEB', domain);
        return webTheme !== undefined ? webTheme : this.getProperty('COLOR_THEME_ID', domain);
    }

    public getDashboards(domain?: string) : Array<string> {
        if(this.getProperty('DASHBOARD_WEB', domain)) {
            return this.getProperty('DASHBOARD_WEB', domain).split(',').map(item => item.trim());
        } else {
            return this.getProperty('DASHBOARD_LIST', domain).split(',').map(item => item.trim());
        }
    }

    public getSessionTimeout(domain?: string) : number {
        let strVal = this.getProperty('SESSION_TIMEOUT',domain);
        return strVal !== undefined && strVal !== '' ? parseInt(strVal) : this.defaultSessionTimeout;
    }
}

const singleInstance = User.Instance;

export default singleInstance;