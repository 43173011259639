module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="table-responsive">\n    <table>\n        <!-- outputing cols to style columns -->\n        ';
 var activateZebra = false; ;
__p += '\n        ';
 for(var grp = 0; grp < headers.length; grp++){ ;
__p += '\n            ';
 var currentGroup = headers[grp] ;
__p += '\n            ';
 var first = true ;
__p += '\n            ';
 for(var i = 0; i < currentGroup.getColumns().length; i++) { ;
__p += '\n                ';
 var currentCol = currentGroup.getColumns()[i]; ;
__p += '\n                ';
 if( ! currentCol.isHidden() && ! _.includes(ruptCols, currentCol.getId())) { ;
__p += '\n                    ';
 if(grp != 0 && first){ ;
__p += '\n                        <col class="group-splitter"/>\n                    ';
 } ;
__p += '\n                    <col class="' +
__e(getColumnClass(currentCol)) +
' ' +
((__t = (activateZebra? 'zebra' : '')) == null ? '' : __t) +
' ' +
((__t = (first? 'first-col' : (i == currentGroup.getColumns().length - 1 ? 'last-col' : ''))) == null ? '' : __t) +
'"/>\n                    ';
 activateZebra = !activateZebra; ;
__p += '\n                    ';
 first = false; ;
__p += '\n                ';
 } ;
__p += '\n            ';
 } ;
__p += '    \n        ';
 } ;
__p += ' \n\n        <!-- outputing THs row-->\n        <tr>\n        ';
 for(var grp = 0; grp < headers.length; grp++){ ;
__p += '\n            ';
 var currentGroup = headers[grp] ;
__p += '\n            ';
 var first = true ;
__p += '\n            ';
 for(var i = 0; i < currentGroup.getColumns().length; i++) { ;
__p += '\n                ';
 var currentCol = currentGroup.getColumns()[i]; ;
__p += '\n                ';
 if( ! currentCol.isHidden() && ! _.includes(ruptCols, currentCol.getId())) { ;
__p += '\n                    ';
 if(grp != 0 && first){ ;
__p += '\n                        ';
 first = false; ;
__p += '\n                        <th class="col-splitter"></th>\n                    ';
 } ;
__p += '\n                    <th style="' +
__e(getHeaderStyle(currentCol, false)) +
'" class="dynamicGridValue">' +
__e(currentCol.getLabel()) +
'</th>\n                ';
 } ;
__p += '\n            ';
 } ;
__p += '    \n        ';
 } ;
__p += ' \n        </tr>\n\n    </table>\n</div>';

}
return __p
}