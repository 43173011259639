import DataRow from 'models/listing/DataRow';
import RuptureRow from 'models/listing/RuptureRow';

import Backbone from 'backbone';
import { SAIView } from '../../../../Additions';

class GridListItem extends SAIView {

    initialize(options) {
        this.rowTemplate = require('ejs-loader!templates/grid/GridRow.ejs');
        this.ruptureTemplate = require('ejs-loader!templates/grid/GridRupture.ejs');
        this.renderingData = options.renderingData || {};
    }

    render() {
        if(this.renderingData.row instanceof RuptureRow){
            this.$el.html(this.ruptureTemplate(this.renderingData));
            this.$el.addClass('rupture-row');
        }else{
            this.$el.html(this.rowTemplate(this.renderingData));
            this.$el.addClass('data-row');
            let me = this;
            this.$el.on('click', function(){
                me.trigger('itemClicked', me.renderingData.row, undefined, me);
            });
        }
    }
}

export default GridListItem;
