'use strict';

import App from 'App';

import Renderer from 'views/listing/renderer/Renderer';
import DataRow from 'models/listing/DataRow';
import RuptureRow from 'models/listing/RuptureRow';
import GridListItem from 'views/listing/renderer/grid/GridListItem';

import ColorUtils from 'utils/ColorUtils';

class GridRenderer extends Renderer{
    initialize(options){
        super.initialize(options);
        this.rowTemplate = require('ejs-loader!templates/grid/GridRow.ejs');
        this.ruptureTemplate = require('ejs-loader!templates/grid/GridRupture.ejs');
        this.template = require('ejs-loader!templates/grid/Grid.ejs');
        this.iconConfig = options.iconConfig;
    }

    renderCore(){
        let ruptCols = [];
        if(this.formatter !== undefined){
            ruptCols = this.formatter.getRuptureColumns();
        }

        this.element.html(this.template({
            headers: this.headers.getColumnsGroupsOrdered(),
            ruptCols: ruptCols,
            getHeaderStyle: this.getHeaderStyle,
            getColumnClass: this.getColumnClass,
        }));
        this.element.addClass('row-list');

        this.itemsContainer = this.element.find('table');
    }

    renderItems(rows, loadMoreCallback){
        var itemsViews = [];

        let ruptCols = [];
        if(this.formatter !== undefined){
            ruptCols = this.formatter.getRuptureColumns();
        }

        let orderedHeaders = this.headers.getColumnsGroupsOrdered();
        for(var rowId in rows){
            let row = rows[rowId];
            let renderingData = this.getRenderingData(row, orderedHeaders, ruptCols);
            this.renderRow(itemsViews, row, renderingData);
        }

        return itemsViews;
    }

    getRenderingData(row, orderedHeaders, ruptCols){
        return {
            headers: orderedHeaders,
            ruptCols: ruptCols,
            getHeaderStyle: this.getHeaderStyle,
            getRowStyle: this.getRowStyle,
            row: row
        };
    }

    renderRow(itemsViews, row, renderingData){
        let newDiv = $('<tr></tr>').appendTo(this.itemsContainer);

        let newRow = new GridListItem({
            el: newDiv,
            renderingData: renderingData
        });
        newRow.render();
        itemsViews.push(newRow);

        if(row instanceof RuptureRow){
            let innerRows = row.getRows();
            for(let innerRowKey in innerRows){
                let innerRow = innerRows[innerRowKey];
                renderingData.row = innerRow;
                this.renderRow(itemsViews, innerRow, renderingData);
            }
        }
    }

    addLoadMore(loadMoreCallback){

    }

    removeLoadMore(){

    }

    /**
     * Returns the style of the row based on its model given as JSON
     *
     * @param {type} row The row model as JSON
     *
     * @returns {String} The style of the column
     */

    getRowStyle(row, visibleIndex) {
        var style = '';
        if (row.getStyles() === undefined) {
            return '';
        }

        for (var key in row.getStyles()) {
            var matchInfo = row.getStyles()[key].match(/rup(tit|tot)(\d)(g|d)/);
            if (matchInfo === null) {
                return '';
            }

            var type = matchInfo[1];
            var level = matchInfo[2];
            var alignement = matchInfo[3];

            var lvlColor = '';
            if (type === 'tit') {
                lvlColor = ColorUtils.shadeColor(App.getConfig().appTheme, Math.min((level-1) / 6, 1));
                if(visibleIndex === 1){
                    style += 'background:' + lvlColor + ';';
                    style += 'color: white;';
                    style += 'text-align: ' + ((alignement === 'g') ? 'left' : 'right') + ';';
                    style += 'font-family: geosanslight;';
                    style+='font-size: 16px;';
                }else{
                    style+='font-size: 14px;';
                }
            }

            style += 'border-bottom: 2px solid ' + lvlColor + ';';
        }

        if (row.hasNote) {
            style += 'background: pink; ';
        }
        return style;
    }

    getColumnClass (header) {
        if (header.grouped) {
            //This column is the beggining of a group. We thus need an extra left margin
            return 'leftColGroup';
        }

        return '';
    }

    /**
     * Returns the column style and alignment based on its properties
     *
     * @param {type} header The header properties
     * @param {type} setAlignment Request for alignment based on the column
     * format
     *
     * @returns {String} The style of the column
     */
    getHeaderStyle(header, setAlignment) {
        var style = '';
        if (header.getWidth()) {
            style = 'width:' + (header.getWidth() * 7) + 'px;';
        }

        if (setAlignment) {
            if (header.getFormat() === 'Integer' || header.getFormat() === 'Financial' || header.getFormat() === 'Number') {
                style += 'text-align: right;';
            } else if (header.getFormat() === 'Date' || header.getFormat() === 'DateTime') {
                style += 'text-align: center;';
            }
        } else {
            style += 'text-align: left;';
        }

        return style;
    }
}

export default GridRenderer;
