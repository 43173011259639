module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if( photoMeta.get('role') === 'display' ) { ;
__p += '\n<div class="albumPhoto" data-index="' +
__e( photoMeta.get('innerId') ) +
'">\n';
 } else { ;
__p += '\n<div class="albumPhoto" data-index="' +
__e( photoMeta.get('innerId') ) +
'">\n';
 } ;
__p += '\n    <div class="photoFlipper">\n        ';
 if( photoMeta.get('role') === 'display' ) { ;
__p += '\n        <div class="frontPhoto">\n        ';
 } else { ;
__p += '\n        <div>\n        ';
 } ;
__p += '\n            <img class="albumPhotoImg" src="' +
__e( photoMeta.get('urlThumb') ) +
'"/>\n            ';
 if( photoMeta.get('role') === 'display' ) { ;
__p += '\n            <div class="loopHolder">\n                <div class="albumPhotoLoop"/>\n                <div class="albumPhotoLoopGlass"/>\n            </div>\n            ';
 } else if( photoMeta.get('role') === 'new' ) { ;
__p += '\n            <div class="frontText">' +
__e( photoMeta.get('frontText')) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n</div>\n';

}
return __p
}